import React from "react";
// import { HashLink } from "react-router-hash-link";
import "./Home.css";
import Icon from "../../components/Icon/Icon.js";
import tvpaint from "../../assets/tvpaint-logo.webp";
import harmony from "../../assets/harmony.webp";
import storyboard from "../../assets/storyboardPro.webp";
import ae from "../../assets/ae-icon.webp";
import ps from "../../assets/ps-icon.webp";
import fable from "../../assets/a-fable.mp4"

function Home() {
  return (
    <main className="home-wrapper" id='home'>
      <section className="video-container">
        <video 
          src={fable}
          title="A Fable, created during the 2023 CCS 48-hour animation challenge"
          frameBorder="none"
          controls
        />
      </section>
      <section className="apps">
        <h1>App Proficiencies</h1>
        <div className="icons">
          <Icon image={harmony} />
          <Icon image={storyboard} />
          <Icon image={ae} />
          <Icon image={ps} />
          <Icon image={tvpaint} />
        </div>
      </section>
    </main>
  );
}

export default Home;
