import React from 'react'
import './Illustrations.css';
import Gallery from '../../components/Gallery/Gallery.js';

function Illustrations() {
  return (
    <section id='illustrations'>
      <Gallery/>
    </section>
  )
}

export default Illustrations