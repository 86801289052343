import funni from "./assets/bg-img2.jpg";
import humbo from "./assets/bg-img3.jpg";
import goats from "./assets/goats.jpg";
import lake from "./assets/lake.jpg";

const images = [
  { id: 1, image: funni, title: "lorem", alt: "desert" },
  { id: 2, image: humbo, title: "lorem", alt: "fox" },
  { id: 3, image: goats, title: "lorem", alt: "goats" },
  { id: 4, image: lake, title: "lorem", alt: "lake" },
];

export default images;
