import React from "react";
import { HashLink } from 'react-router-hash-link';

import "./Header.css";

function Header() {
  return (
    <header>
      <div className="logo">
        <h1>Brendan Connell</h1>
        <span>Animator</span>
      </div>
      <nav>
        <HashLink smooth to="#home">
          Home
        </HashLink>
        <HashLink smooth to="#illustrations">
          Illustrations
        </HashLink>
        <HashLink smooth to="#contact">
          Contact
        </HashLink>
      </nav>
    </header>
  );
}

export default Header;
