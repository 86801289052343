import React from 'react'
import './Icon.css';

function Icon({ image }) {
  return (
    <div className='icon-container'>
        <img src={image} alt='icon'/>
    </div>
  )
}

export default Icon