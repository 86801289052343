import React from "react";
import Socials from "../Socials/Socials";
import "./Footer.css";

function Footer() {
  return (
    <footer>
      <Socials />
      <span>Copyright © Brendan Connell 2024</span>
      <span>
        Site Manager:{" "}
        <a className="contact-link" href="https://www.linkedin.com/in/michael-vitale-1a010022b/" target="_blank" rel='noopener noreferrer'>
          Michael Vitale
        </a>
      </span>
    </footer>
  );
}

export default Footer;
