import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Home from "./sections/Home/Home.js";
// import About from "./sections/About/About.js";
import Contact from "./sections/Contact/Contact.js";
import Illustrations from "./sections/Illustrations/Illustrations.js";
// import Animations from "./pages/Animations/Animations.js";

function App() {
  return (
    <>
      <Header />
      <Home/>
      <Illustrations/>
      <Contact/>
      <Footer />
    </>
  );
}

export default App;
